<script lang="ts" setup>
const { slicedGames, menu, showLoadMoreButton, favorites, load, handleLoadMoreClick } = useGamesCatalog();

defineProps<{
	showCatalog: boolean;
}>();

const observerElement = ref<HTMLElement | null>(null);
const gamesConteiner = ref<HTMLElement | null>(null);
let observer: IntersectionObserver | null = null;
const activeTab = ref("lobby");

const scrollIntoCatalog = () => {
	if (!isClient) {
		return;
	}

	if (window?.scrollY > (gamesConteiner.value?.offsetTop ?? 0)) {
		window.scrollTo({
			top: gamesConteiner.value?.offsetTop,
			behavior: "smooth"
		});
	}
};

const handleClickTab = (link: string) => {
	const item = link.split("/")[2];
	activeTab.value = item;
	scrollIntoCatalog();

	if (item === "lobby") {
		return;
	}

	load(item);
};

onMounted(() => {
	observer = new IntersectionObserver(
		(entries) => {
			if (entries?.[0]?.isIntersecting && showLoadMoreButton.value) {
				handleLoadMoreClick();
			}
		},
		{
			root: null,
			rootMargin: "200px",
			threshold: [0.5]
		}
	);

	if (observerElement.value) {
		observer?.observe(observerElement.value);
	}
});

onUnmounted(() => {
	if (observer) {
		observer.disconnect();
	}
});
</script>

<template>
	<div ref="gamesConteiner" />
	<div :class="['catalog', { fixed: !showCatalog }]">
		<OGamesNavigation :menu="menu" type="button" show-lobby class="wrapper" @click-tab="handleClickTab" />
		<template v-if="showCatalog">
			<LazyOGames v-if="slicedGames?.length && (favorites?.length || activeTab !== 'favorites')" :games="slicedGames" />
			<template v-if="activeTab === 'favorites' && !favorites?.length">
				<MGamesFavoriteEmpty />
				<OGameSlider
					v-if="slicedGames?.length"
					class="section-item row-games"
					:games="slicedGames"
					title="Recently Played"
					icon="24/recent"
				/>
			</template>
		</template>

		<div ref="observerElement" class="border" />
	</div>
</template>

<style scoped lang="scss">
.wrapper {
	width: 100%;

	&:deep(+ *:not(.border)) {
		padding-top: 12px;
	}
}

.fixed {
	position: sticky;
	top: var(--top-height);
	z-index: 123;
}

.catalog {
	max-width: 1428px;
	margin: 0 auto;
	padding: 0 32px;

	@include media-breakpoint-down(md) {
		padding: 0 16px;
	}

	&:deep(.fixed + .section-item) {
		padding-top: 20px;
	}

	&:deep(.row-bar) {
		width: calc(100% + 16px);
		transform: translateX(-8px);
		gap: 8px;

		.nav {
			padding: 0 4px 20px;
		}

		@include media-breakpoint-down(md) {
			width: calc(100% + 32px);
			transform: translateX(-16px);
			margin: 0;

			.nav {
				padding: 0 16px 20px;
			}
		}

		@media screen and (orientation: landscape) and (max-width: 1023px) {
			width: calc(100% + 64px);
			transform: translateX(-32px);

			.nav {
				padding: 0 32px 20px;
			}
		}
	}
}
</style>
